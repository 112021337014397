<template>
  <div class="content-body">
    <b-sidebar
      v-if="sliderVal"
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <div v-if="isAddData && sliderVal" class="pading-20">
        <validation-observer ref="simpleRules">
          <div class="addDetails">Add Standard</div>
          <label>Name</label>
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <input
              v-model="standardName"
              placeholder="name"
              class="mr-bottom form-control input-sm flex"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <br />
          <label>Version</label>
          <input
            v-model="standardVersion"
            placeholder="version"
            class="mr-bottom form-control input-sm flex"
          />
          <label>Type</label>
          <!-- <v-select
          v-model="selectedData"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
        /> -->
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <select name="" id="" class="form-control" v-model="selectedData">
              <option value="Security">Security</option>
              <option value="Privacy">Privacy</option>
            </select>
            <small class="text-danger mb-1">{{ errors[0] }}</small>
          </validation-provider>
          <br />
          <label>Description</label>
          <b-form-textarea
            v-model="standardDescription"
            placeholder="description"
            class="mr-bottom form-control input-sm flex"
          />
          <!-- <div class="flex-display">
          <label class="label-text">Is Primary</label>
          <b-form-checkbox v-model="isPrimary" :value="selectedPrimary">
          </b-form-checkbox>
          <label class="label-text">Is Published</label>
          <b-form-checkbox v-model="isPublished" :value="selectedisPublished">
          </b-form-checkbox>
        </div> -->
          <div>
            <label for="tags-basic">Country</label>
            <!-- <b-form-tags v-model="region" input-id="tags-basic" class="mb-2" /> -->
            <v-select   :options="countryOptions" :reduce="opt => opt.value" label="text" v-model="region" multiple class="mb-1 custom-select-variant">
              <!-- <option v-for="(option,index) in countryOptions" :value="option.value" :key="index">{{ option.text }}</option> -->
              
            </v-select>
            <b-form-checkbox 
              v-model="countryChecked"
              @change="region=[]" >
            set as global
            </b-form-checkbox>
          </div>
          <label>Industry</label>
          <!-- <input
            v-model="standardIndustry"
            placeholder="industry"
            class="mr-bottom form-control input-sm flex"
          /> -->
          <v-select   :options="industryOptions" :reduce="opt => opt.value" label="text" v-model="standardIndustry" multiple class="mb-1 custom-select-variant">
            <!-- <option v-for="(option,index) in industryOptions" :value="option.value" :key="index">{{ option.text }}</option> -->
            </v-select>
            <b-form-checkbox 
            label="set as global"
              v-model="industryChecked"
              @change="standardIndustry=[]" class="mb-2 ">
        set as global

            </b-form-checkbox >
          <div class="align-right">
            <button
              @click="addStandardVal"
              class="btn pointer btn-primary btn-md margin-right"
            >
              Add
            </button>
            <button @click="cancel" class="btn pointer btn-md btn-secondary">
              Cancel
            </button>
          </div>
        </validation-observer>
      </div>

      <div v-else class="pading-20">
        <b-tabs content-class="mt-1">
          <b-tab title="Edit Standard" active>
            <div v-if="editDatainput && sliderVal" class="addDetails">
              Edit Standard
            </div>

            <label>Name</label>
            <input
              v-model="standardName"
              placeholder="name"
              class="mr-bottom form-control input-sm flex"
            />
            <label>Version</label>

            <input
              v-model="standardVersion"
              placeholder="version"
              class="mr-bottom form-control input-sm flex"
            />
            <label>Type</label>
            <!-- <v-select
          v-model="selectedData"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
        /> -->
            <select name="" id="" class="form-control" v-model="selectedData">
              <option value="Security">Security</option>
              <option value="Privacy">Privacy</option>
            </select>
            <label>Description</label>
            <b-form-textarea
              v-model="standardDescription"
              placeholder="description"
              class="mr-bottom form-control input-sm flex"
            />

            <div>
              <label for="tags-basic">Country</label>
            <!-- <b-form-tags v-model="region" input-id="tags-basic" class="mb-2" /> -->
            <v-select   :options="countryOptions" :reduce="opt => opt.value" label="text" v-model="region" multiple class="mb-1">
              <!-- <option v-for="(option,index) in countryOptions" :value="option.value" :key="index">{{ option.text }}</option> -->
              
            </v-select>
            <b-form-checkbox 
              v-model="countryChecked"
              @change="region=[]" >
            set as global
            </b-form-checkbox>
          </div>
          <label>Industry</label>
          <!-- <input
            v-model="standardIndustry"
            placeholder="industry"
            class="mr-bottom form-control input-sm flex"
          /> -->
          <v-select class="mb-1" :options="industryOptions" :reduce="opt => opt.value" label="text" v-model="standardIndustry" multiple>
            <!-- <option v-for="(option,index) in industryOptions" :value="option.value" :key="index">{{ option.text }}</option> -->
            </v-select>
            <b-form-checkbox 
            label="set as global"
              v-model="industryChecked"
              @change="standardIndustry=[]" class="mb-2 ">
        set as global

            </b-form-checkbox >

            <label>Controls</label>
            <div
              class="w-100 border-light rounded d-flex align-items-center justify-content-between p-1 mb-75"
              style="border-width:1px !important;"
            >
              <b-form-group label="Sort By" label-size="sm" style="width: 48%">
                <b-form-select v-model="sort_controls_by" :options="sortByOptions" class="w-100" />
              </b-form-group>

              <b-form-group label="Sort Order" label-size="sm" style="width: 48%">
                <b-form-select v-model="sort_controls_order" :options="sortOrderOptions" class="w-100" />
              </b-form-group>
            </div>

            <div class="align-right">
              <button
                @click="saveEditData()"
                class="btn pointer btn-primary btn-md margin-right"
                :disabled="
                  !this.$store.state.auth.permissions.includes(
                    'standard-update'
                  )
                "
              >
                Update
              </button>
              <button
                @click="editCancel"
                class="btn pointer btn-md btn-secondary"
              >
                Cancel
              </button>
            </div>
          </b-tab>
          <b-tab title="Subsets">
            <div class="mt-1">
              <label for="" class="mb-1"><b>Add Subset</b></label>
              <br />
              <button
                class="btn btn-primary mb-1 btn-sm"
                @click="isHidden = !isHidden"
                :disabled="
                  !this.$store.state.auth.permissions.includes('subset-create')
                "
              >
                <span v-if="!isHidden"
                  ><feather-icon icon="ArrowUpIcon"
                /></span>
                <span v-else><feather-icon icon="PlusIcon" /></span>
              </button>
              <div v-if="!isHidden">
                <input
                  type="text"
                  class="form-control mb-1"
                  v-model="subsetname"
                  placeholder="Enter Subset name"
                />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  v-model="subsetdescription"
                  class="form-control mb-1"
                  placeholder="Description"
                ></textarea>
                <button
                  class="btn btn-primary btn-sm mb-1"
                  @click="createSubset"
                >
                  Add Subset
                </button>
              </div>
            </div>
            <div class="mt-1">
              <div>
                <div v-for="subset in subsetlist" :key="subset">
                  <div class="d-flex justify-content-between">
                    <div class="w-75">
                      <h6 class="mb-25">{{ subset.name }}</h6>
                      <p>{{ subset.description }}</p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-button
                        variant="flat-primary"
                        class="btn-icon"
                        @click="editSubset(subset._id)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        variant="flat-danger"
                        class="btn-icon"
                        :disabled="subset.can_delete == '0'"
                        :title="
                          subset.can_delete == '0'
                            ? 'Cant Delete,subset already published'
                            : ''
                        "
                        @click="deleteSubsetModal(subset._id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- {{subsetlist}} -->
            </div>
          </b-tab>
          <b-tab title="Response Set">
            <label for="">Add New Response Set</label>
            <br />
            <button
              class="btn btn-primary mb-1 btn-sm"
              @click="isHidden = !isHidden"
            >
              <span v-if="!isHidden"><feather-icon icon="ArrowUpIcon" /></span>
              <span v-else><feather-icon icon="PlusIcon" /></span>
            </button>
            <div v-if="!isHidden">
              <div
                class="d-flex flex-row align-items-center justify-content-between mb-50 w-100 position-relative"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-50 mr-1"
                >
                  <p class="mb-0 font-weight-bold">Response</p>
                </div>

                <div
                  class="d-flex align-items-center justify-content-start w-50"
                >
                  <p class="mb-0 font-weight-bold">Completion</p>
                </div>
              </div>
              <div class="d-flex">
                <input
                  type="text"
                  class="form-control mr-1"
                  v-model="resTitle"
                />
                <input
                  type="number"
                  class="form-control"
                  v-model="resValue"
                  @input="duplicationCheck()"
                />
              </div>

              <span
                v-if="resValue > 100"
                class="text-danger"
                style="font-size: 12px"
                >Value must be in between 1 and 99</span
              >
              <span class="text-danger" style="font-size: 12px" v-if="found"
                >duplicate value is not allowed</span
              >
              <br />
              <button
                class="btn btn-primary btn-sm mb-1 mt-1"
                @click="addResSet"
                :disabled="resValue == '' || resValue > 100"
              >
                Add
              </button>
              <br />
            </div>
            <div
              class="d-flex flex-row align-items-center justify-content-between mb-50 w-100 position-relative"
            >
              <div
                class="d-flex align-items-center justify-content-start w-50 mr-1"
              >
                <p class="mb-0 font-weight-bold">Response</p>
              </div>

              <div class="d-flex align-items-center justify-content-start w-50">
                <p class="mb-0 font-weight-bold">Completion</p>
              </div>
            </div>
            <div
              class="d-flex mb-1"
              v-for="(response, index) in responseset"
              :key="response"
            >
              <input
                type="text"
                class="form-control mr-1"
                v-model="response.title"
              />
              <input
                type="Number"
                class="form-control"
                v-model="response.value"
                min="0"
                max="100"
                :disabled="response.value == 0 || response.value == 100"
              />

              <span
                @click="removeSet(index)"
                class="ml-1 text-danger"
                style="cursor: pointer"
                v-if="index > 1"
                ><feather-icon
                  class="text-danger pointer rotate"
                  icon="Trash2Icon"
                  size="15"
              /></span>
            </div>

            <!-- {{ this.resStandardId }} -->
            <button class="btn btn-primary" @click="updateResSet(standardID)">
              Update Response Set
            </button>
          </b-tab>
          <b-tab title="Tags">
            <label for="">Add New Tag</label>
            <div class="d-flex">
              <input
                type="text"
                class="form-control mr-50"
                placeholder="Title"
                v-model="tag_title"
              />
              <button
                class="btn btn-primary btn-sm"
                @click="addTag()"
                :disabled="this.tag_title == ''"
              >
                Add
              </button>
            </div>
            <div class="mt-1">
              <div
                v-for="tag in this.tagList"
                :key="tag._id"
                class="d-flex align-items-center justify-content-between w-100 mb-50"
                style="
                  border: 1px solid #ccc;
                  padding: 5px 10px;
                  border-radius: 5px;
                "
              >
                <div>{{ tag.title }}</div>
                <div>
                  <b-button
                    variant="flat-primary"
                    class="btn-icon"
                    @click="editTag(tag._id)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <!--subset-->
      </div>
    </b-sidebar>
    <div class="card p-2 bg-card">
      <div class="align-center-flex">
        <div class="d-flex">
          <input
            v-model="searchResultstd"
            placeholder="Search"
            class="form-control input-sm mr-1"
            style="max-width: 200px"
          />
          <select
            name=""
            id=""
            class="form-control mr-1"
            v-model="type"
            @change="typechanged"
            style="max-width: 200px"
          >
            <option value="" selected>Security/Privacy</option>
            <option value="Security">Security</option>
            <option value="Privacy">Privacy</option>
          </select>
          <select
            name=""
            id=""
            class="form-control"
            v-model="ispublished"
            @change="privacychanged"
            style="max-width: 230px"
          >
            <option value="" selected>Published/Unpublished</option>
            <option value="1">Published</option>
            <option value="2">Unpublished</option>
          </select>
        </div>
        <button
          v-b-toggle.sidebar-right
          @click="addStandards()"
          class="btn pointer btn-outline-primary btn-sm margin-right"
        >
          + Add
        </button>
      </div>
    </div>

    <div class="card p-2">
      <div class="card-company-table card card-congratulation-medal">
        <div class="mb-0 table-responsive border rounded">
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Version</th>
                <th role="columnheader" scope="col">Type</th>
                <th role="columnheader" scope="col">Primary</th>
                <th role="columnheader" scope="col">Published</th>
                <th role="columnheader" scope="col">Countries</th>
                <th role="columnheader" scope="col">Industries</th>
                <th role="columnheader" scope="col" class="align-text">
                  Action
                </th>
                <!-- <th role="columnheader" scope="col" class="align-text">Delete</th> -->
              </tr>
            </thead>
            <tbody class="rowgroup">
              <tr
                v-for="(standard, index) in allStandardsData"
                :key="standard._id"
                role="row"
                class="custom-table-row pointer"
              >
                <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                <td @click="getDetails(standard._id)" role="cell">
                  <div class="d-flex">
                    <span>
                      <h6
                        style="text-transform: capitalize"
                        :title="standard.name"
                      >
                        {{
                          standard.name.length < 50
                            ? standard.name
                            : standard.name.substring(0, 50) + "..."
                        }}
                      </h6>

                      <p
                        style="font-size: 13px; font-style: italic"
                        :title="standard.description"
                      >
                        {{
                          standard.description.length < 50
                            ? standard.description
                            : standard.description.substring(0, 50) + "..."
                        }}
                      </p>
                    </span>
                  </div>
                </td>
                <td role="cell">
                  {{ standard.version }}
                </td>
                <td role="cell">
                  <b-badge
                    pill
                    :variant="
                      standard.type == 'Security' ? 'primary' : 'warning'
                    "
                    >{{ standard.type }}</b-badge
                  >
                </td>
                <td role="cell">
                  <span v-if="standard.is_primary == 1">
                    <b-form-checkbox
                      v-model="selectedPrimary"
                      :value="selectedPrimary"
                    >
                    </b-form-checkbox>
                  </span>
                </td>
                <td role="cell">
                  <span v-if="standard.is_published == 1">
                    <b-form-checkbox
                      v-model="selectedisPublished"
                      :value="selectedisPublished"
                    >
                    </b-form-checkbox>
                  </span>
                </td>
                <td role="cell">
                  <span v-for="(country,index) in standard.countries" :key="country._id">
                    {{ country.name }}{{ index===standard.countries.length-1?'':',' }}
                  </span>
                </td>

                <td role="cell">
                  <span v-for="(industry,index) in standard.industries" :key="industry._id">
                    {{ industry.name }}{{ index===standard.countries.length-1?'':',' }}
                  </span>
                </td>
                <td role="cell" class="d-flex">
                  <!-- <span
                    v-b-toggle.sidebar-right
                    @click="viewDetails(standard)"
                    class="icon-box-new"
                  >
                    <feather-icon
                      class="pointer  rotate"
                      icon="EyeIcon"
                      size="20"
                    />
                  </span> -->
                  <span
                    v-b-toggle.sidebar-right
                    @click="EditStandard(standard)"
                    class="icon-box-new"
                  >
                    <feather-icon
                      class="pointer  rotate"
                      icon="Edit2Icon"
                      size="20"
                    />
                  </span>
                  <span
                    @click="goToDraft(standard._id)"
                    class="icon-box-new"
                    v-if="standard.is_published == 1"
                  >
                    <feather-icon
                      class="pointer rotate"
                      icon="ClipboardIcon"
                      size="20"
                    />
                  </span>
                  <span
                    @click="openDeleteModel(standard._id)"
                    class="icon-box-new"
                    v-else
                  >
                    <feather-icon
                      class="text-danger pointer rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                </td>
                <!-- <td role="cell" class="align-center">
                               
                            </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-1">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <p>
              Showing {{ this.from }} to {{ this.to }} of
              {{ this.total }} entries
            </p>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              hide-goto-end-buttons
              :total-rows="lastPage * perPage"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- delete standard modal -->
    <b-modal
      ref="modal-delete-standard"
      title="Are you sure to delete? "
      hide-footer
    >
      <button
        class="btn btn-danger"
        @click="deleteStandard(stdid)"
        :disabled="
          !this.$store.state.auth.permissions.includes('standard-delete')
        "
      >
        Delete Standard
      </button>
    </b-modal>

    <!-- subset edit modal -->
    <b-modal ref="modal-editsubset" title="Edit Subset " hide-footer>
      <input
        type="text"
        class="form-control"
        v-model="subset_name"
        placeholder="Subset name"
      />
      <textarea
        name=""
        id=""
        cols="30"
        rows="5"
        class="form-control mt-1 mb-1"
        v-model="subset_description"
        placeholder="description"
      ></textarea>
      <input
        type="text"
        class="form-control mt-1 mb-1"
        v-model="version_subset"
        placeholder="version"
      />
      <button
        class="btn btn-primary btn-sm mr-1"
        @click="updateSubset(subsetdetails._id)"
        :disabled="
          !this.$store.state.auth.permissions.includes('subset-update')
        "
      >
        Update
      </button>
      <button class="btn btn-secondary btn-sm" @click="hideEditModal()">
        Cancel
      </button>
    </b-modal>

    <!-- subset delete modal -->
    <b-modal
      ref="modal-delete-subset"
      title="Are you sure to delete? "
      hide-footer
    >
      <button
        class="btn btn-danger"
        @click="deleteSubset()"
        :disabled="
          !this.$store.state.auth.permissions.includes('subset-delete')
        "
      >
        Delete Subset
      </button>
    </b-modal>
    <b-modal title="Edit Tag" ref="edit-tags" hide-footer>
      <div>
        <input type="text" class="form-control mb-1" v-model="edit_tag_title" />
        <button class="btn btn-primary mr-50" @click="updateTag()">
          Update
        </button>
        <button class="btn btn-secondary" @click="cancelEditTag()">
          Cancel
        </button>
      </div>
    </b-modal>
    <!-- response set modal -->
    <b-modal ref="modal-response-set" title="Response Set " hide-footer>
      <label for="">Add New Response Set</label>
      <br />
      <button class="btn btn-primary mb-1 btn-sm" @click="isHidden = !isHidden">
        <span v-if="!isHidden"><feather-icon icon="ArrowUpIcon" /></span>
        <span v-else><feather-icon icon="PlusIcon" /></span>
      </button>
      <div v-if="!isHidden">
        <div class="d-flex">
          <input type="text" class="form-control mr-1" v-model="resTitle" />
          <input type="number" class="form-control" v-model="resValue" />
        </div>
        <span v-if="resValue > 100" class="text-danger" style="font-size: 12px"
          >Value must be in between 0 and 100</span
        >

        <br />
        <button
          class="btn btn-primary btn-sm mb-1 mt-1"
          @click="addResSet"
          :disabled="resValue == '' || resValue > 100"
        >
          Add
        </button>
        <br />
      </div>
      <div
        class="d-flex mb-1"
        v-for="(response, index) in responseset"
        :key="response"
      >
        <input type="text" class="form-control mr-1" v-model="response.title" />
        <input
          type="Number"
          class="form-control"
          v-model="response.value"
          min="0"
          max="100"
          :disabled="response.value == 0 || response.value == 100"
        />
        <span
          @click="removeSet(index)"
          class="ml-1 text-danger"
          style="cursor: pointer"
          v-if="index > 1"
          ><feather-icon
            class="text-danger pointer rotate"
            icon="Trash2Icon"
            size="15"
        /></span>
      </div>
      <!-- {{responseset}} -->

      <!-- {{ this.resStandardId }} -->
      <button class="btn btn-primary" @click="updateResSet(resStandardId)">
        Update Response Set
      </button>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { BFormFile } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import moment from "moment";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BFormCheckbox,
  BFormTags,
  BPagination,
  BButton,
  BAvatar,
  BBadge,
  BModal,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { BTabs, BTab } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  data: () => ({
    allStandardsData: [],
    isAddData: false,
    standardName: "",
    standardDescription: "",
    standardRegion: "",
    standardIndustry: [],
    sliderVal: false,
    editDatainput: false,
    standardVersion: "",
    standardID: "",
    selectedPrimary: false,
    selectedisPublished: false,
    sort_controls_by: "created_at",
    sort_controls_order: "asc",
    isPrimary: true,
    isPublished: true,
    version: "",
    region: [],
    sortByOptions: [
      { text: "Created Time", value: "created_at" },
      { text: "Control Number", value: "control_no" },
    ],
    sortOrderOptions: [
      { text: "Ascending", value: "asc" },
      { text: "Descending", value: "desc" },
    ],
    countryOptions:[],
    industryOptions:[],
    option: [{ title: "Security" }, { title: "Privacy" }],
    selectedData: [],
    dir: "ltr",
    stdid: "",
    required,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    to: "",
    from: "",
    total: "",
    searchResultstd: "",
    subsetname: "",
    subsetdescription: "",
    subsetlist: [],
    subsetdetails: [],
    subset_name: "",
    subset_description: "",
    version_subset: "",
    subsetId: "",
    isHidden: true,
    type: "",
    ispublished: "",
    responseset: [],
    resStandardId: "",
    resTitle: "",
    resValue: "",
    found: false,
    tag_title: "",
    tagList: [],
    tagDetails: [],
    edit_tag_title: "",
    editModal: false,
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BFormCheckbox,
    vSelect,
    BFormTags,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BButton,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BModal,
    BFormSelect,
    BFormGroup,
  },
  setup() {
    return {
      avatarText,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  computed:{
    countryChecked(){
      return !this.region?.length
    },
    industryChecked(){
      return !this.standardIndustry?.length
    }
  },
  props: {},
  watch: {
    currentPage(value) {
      this.getStandards(value);
    },
    searchResultstd() {
      this.getStandards(this.currentPage);
    },
    sliderVal() {
      this.getSubset(this.standardID);
      this.getTags(this.standardID);
    },
  },
  mounted() {
    this.getStandards(this.currentPage);
    this.getCountries()
    this.getIndustries()
  },

  methods: {
    viewDetails(standard){
      this.$router.push(`/standard/${standard._id}/view`)
    },
    cancelEditTag() {
      this.$refs["edit-tags"].hide();
    },
    async getCountries(){
      const options={
        method:"GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          
        },
        url:`${process.env.VUE_APP_ONECSF_API_URL}/countries`,
        params:{dont_paginate:true}
      }
      try {
        const response=await this.$http(options)
        const option=response.data.data.map(item=>{
          return {text:item.name,value:item._id}
        })

        this.countryOptions=[...this.countryOptions,...option]

      } catch (error) {
        console.log(error)
      }
    },
    async getIndustries(){
      const options={
        method:"GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          
        },
        url:`${process.env.VUE_APP_ONECSF_API_URL}/industries`,
        params:{dont_paginate:true}
      }
      try {
        const response=await this.$http(options)
        const option=response.data.data.map(item=>{
          return {text:item.name,value:item._id}
        })

        this.industryOptions=[...this.industryOptions,...option]

      } catch (error) {
        console.log(error)
      }
    },
    updateTag() {
      const data = {
        standard_id: this.standardID,
        title: this.edit_tag_title,
      };
      const options = {
        method: "POST",
        data: data,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment-question-tags/${this.tagDetails._id}`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          this.$refs["edit-tags"].hide();

          this.getTags(this.standardID);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    editTag(id) {
      this.$refs["edit-tags"].show();
      this.getTagDetails(id);
    },
    getTagDetails(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment-question-tags/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.tagDetails = res.data.data;
          this.edit_tag_title = this.tagDetails.title;
        })
        .catch((err) => {});
    },
    addTag() {
      const data = {
        standard_id: this.standardID,
        title: this.tag_title,
      };
      const options = {
        method: "POST",
        data: data,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/assessment-question-tags`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          this.tag_title = "";
          this.getTags(this.standardID);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    getTags(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/assessment-question-tags?standard_id=${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.tagList = res.data.data.data;
        })
        .catch((err) => {});
    },
    duplicationCheck() {
      for (var i = 0; i < this.responseset.length; i++) {
        if (this.responseset[i].value == this.resValue) {
          this.found = true;
          break;
        } else {
          this.found = false;
        }
      }
    },
    removeSet(index) {
      this.responseset.splice(index, 1);
      // let obj = this.responseset.find(o => o.value === 100 && 0);
      // if(obj){

      // }
    },

    addResSet() {
      const a = {
        title: this.resTitle,
        value: this.resValue,
      };
      if (this.responseset) {
        this.responseset.push(a);
      } else {
        this.responseset = [];
        this.responseset.push(a);
        // this.responseset = newset;
      }

      this.resTitle = "";
      this.resValue = "";
    },
    updateResSet(val) {
      const data = {
        response_set: this.responseset,
      };
      const options = {
        method: "POST",
        data: data,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url:
          process.env.VUE_APP_ONECSF_API_URL + `/standards/${val}/response-set`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          // this.getSubset(this.standardID);
          this.getStandards(this.currentPage);
          this.$refs["modal-response-set"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    openResponseSet(response) {
      this.resStandardId = response.data._id;
      this.responseset = response.data.response_set;
      this.$refs["modal-response-set"].show();
    },

    typechanged() {
      this.getStandards(this.currentPage);
    },
    privacychanged() {
      this.getStandards(this.currentPage);
    },
    //subset functions

    deleteSubset() {
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standard_subset/${this.subsetId}`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          this.getSubset(this.standardID);
          this.$refs["modal-delete-subset"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteSubsetModal(id) {
      this.subsetId = id;
      this.$refs["modal-delete-subset"].show();
    },
    hideEditModal() {
      this.$refs["modal-editsubset"].hide();
    },
    updateSubset(id) {
      const data = {
        name: this.subset_name,
        description: this.subset_description,
        version: this.version_subset,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          this.getSubset(this.standardID);
          this.$refs["modal-editsubset"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    editSubset(id) {
      this.$refs["modal-editsubset"].show();
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url:
          process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}/detail`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetdetails = res.data.data;
          this.subset_name = res.data.data.name;
          this.subset_description = res.data.data.description;
          this.version_subset = res.data.data.version;
        })
        .catch((err) => {});
    },
    createSubset() {
      const data = {
        parent_id: this.standardID,
        name: this.subsetname,
        description: this.subsetdescription,
      };
      const options = {
        method: "POST",
        data: data,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset`,
      };

      this.$http(options)
        .then((res) => {
          // (this.isAddData = false), (this.sliderVal = false);
          this.getSubset(this.standardID);
          this.subsetname = "";
          this.subsetdescription = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    getSubset(id) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standard_subset/${id}`,
      };

      this.$http(options)
        .then((res) => {
          this.subsetlist = res.data.data;
        })
        .catch((err) => {});
    },
    //subset functions end

    addStandardVal() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.saveStandarads();
        }
      });
    },
    openDeleteModel(standard) {
      this.stdid = standard;
      this.$refs["modal-delete-standard"].show();
    },
    getStandards(pageno) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        params: { page: pageno, limit: this.perPage },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards?name=${this.searchResultstd}&type=${this.type}&is_published=${this.ispublished}`,
      };
      this.$http(options)
        .then((res) => {
          this.allStandardsData = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;
          this.to = res.data.data.to;
          this.from = res.data.data.from;
          this.total = res.data.data.total;
          if (res.data.data.is_primary == "1") {
            this.selectedPrimary = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDetails(id) {
      this.$router.push({ name: "Standard", params: { id: id } });
      const data = this.allStandardsData.filter((ele) => ele._id == id);
      (this.$store.state.standardName = data[0].name),
        (this.$store.state.standardID = data[0]._id);
    },
    goToDraft(id) {
      this.$router.push({ name: "Standarddraft", params: { id: id } });
    },
    addStandards() {
      this.isAddData = true;
      this.sliderVal = true;
      this.editDatainput = false;
      (this.standardName = ""),
        (this.standardDescription = ""),
        (this.standardRegion = ""),
        (this.standardIndustry = ""),
        (this.standardVersion = ""),
        (this.standardID = ""),
        (this.selectedData = []);
    },
    saveStandarads() {
      const body = {
        name: this.standardName,
        description: this.standardDescription,
        industry_ids: this.standardIndustry,
        country_ids: this.region,
        version: this.standardVersion,
        type: this.selectedData,

        // is_primary: this.isPrimary ? 1 : 0,
        // is_published: this.isPublished ? 1 : 0,
      };
      const options = {
        method: "POST",
        data: body,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },

        url: process.env.VUE_APP_ONECSF_API_URL + `/standards`,
      };

      this.$http(options)
        .then((res) => {
          (this.isAddData = false), (this.sliderVal = false);

          this.openResponseSet(res.data);
          this.getStandards();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    EditStandard(editData) {
      console.log(editData, "EDIT");

      this.sliderVal = true;
      this.isAddData = false;
      this.editDatainput = true;
      this.standardName = editData.name;
      this.standardDescription = editData.description;
      this.region = editData.country_ids;
      this.standardIndustry = editData.industry_ids;
      this.standardVersion = editData.version;
      this.standardID = editData._id;
      this.isPrimary = editData.is_primary === 1 ? true : false;
      this.isPublished = editData.is_published === 1 ? true : false;
      this.selectedData = editData.type;
      this.responseset = editData.response_set;
      this.sort_controls_by = editData.sort_controls_by;
      this.sort_controls_order = editData.sort_controls_order
      // this.selectedData.push({ title: editData.type });
      console.log(this.isPrimary);
      this.getSubset(this.standardID);

      // this.type = this.editData.
    },
    updateDetails(editData) {
      // this.standardName = editData.name,
      // this.standardDescription = editData.description,
      // this.standardRegion = editData.region,
      // this.standardIndustry = editData.industry,
      // this.standardVersion = editData.version
      //   this.saveEditData(editData)
    },
    saveEditData() {
      console.log(this.isPrimary);
      let data = {
        name: this.standardName,
        description: this.standardDescription,
        industry_ids: this.standardIndustry,
        country_ids: this.region,
        version: this.standardVersion,
        type: this.selectedData,
        sort_controls_by: this.sort_controls_by,
        sort_controls_order: this.sort_controls_order,
        // is_primary: this.isPrimary ? 1 : 0,
        // is_published: this.isPublished ? 1 : 0,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/standards/${this.standardID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getStandards();
          this.sliderVal = false;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteStandard(id) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-delete-standard"].hide();
          this.getStandards();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.name[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
      setTimeout(this.showTeamList, 5000);
    },
    cancel() {
      (this.sliderVal = false), (this.isAddData = false);
    },
    editCancel() {
      (this.editDatainput = false),
        (this.sliderVal = false),
        (this.standardName = ""),
        (this.standardDescription = ""),
        (this.region = ""),
        (this.standardIndustry = ""),
        (this.standardVersion = ""),
        (this.standardID = ""),
        (this.isPrimary = true),
        (this.isPublished = true),
        (this.selectedData = []);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.dark-layout{
  .vs__selected {
    background-color: rgba(0, 207, 232, 0.12) !important; /* Example of a Bootstrap 'primary' color */
    color: #007bff !important;
  }
  .vs__deselect>svg{
    fill:#007bff !important
  }
  .vs__dropdown-menu li:hover{

    color: #fff !important;
  }
}
.editContainer {
  padding: 20px;
}
.pading-20 {
  padding: 10px 50px;
}
.mr-bottom {
  margin-bottom: 10px;
}
.rowgroup tr:hover {
  background-color: #f6f6f9;
  user-select: none;
}
.pointer {
  cursor: pointer;
}
// .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
// .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
//   background-color: #f3f2f7 !important;
//   color: #6e6b7b;
// }
.align-right {
  display: flex;
  justify-content: flex-end;
}
.addDetails {
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
  padding-left: 0px;
}
.margin-right {
  margin-right: 10px;
}
.text-primary-black {
  color: #000;
}
.icon-box-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.align-text {
  text-align: center;
}
.align-center {
  justify-content: center;
  text-align: center;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.flex-display {
  display: flex;
}
.b-sidebar {
  width: 50% !important;
}
.label-text {
  margin-right: 10px;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
th:last-child,
td:last-child {
  // position:sticky;
  // right:0px;
  // background: #fafafa;
}
.bg-card {
  background: -webkit-linear-gradient(70deg, #968df3 70%, #472183 40%);
  background: -o-linear-gradient(70deg, #968df3 70%, #472183 40%);
  background: -moz-linear-gradient(70deg, #968df3 70%, #472183 40%);
  background: linear-gradient(70deg, #968df3 70%, #472183 40%);
}
th {
  background: #472183 !important;
  color: #fff;
}
.btn-outline-primary {
  color: #fff;
}
</style>
